@import '../../../scss/utilities';

.headline {
  margin: 0;
  font-family: $ue-header-font;
  font-weight: 700;
  font-size: $ue-font-size-7;
  line-height: 1.3;
  @include media-breakpoint-up(md) {
    font-size: $ue-font-size-5;
  }
  &_padded {
    padding-bottom: $grid-gutter-width*0.5;
  }
}

.headline__link {
  color: $ue-black;
  &:focus,
  &:hover {
    color: $ue-black;
  }
}
