@import '../../../../scss/utilities';

.filters {
  @include make-row();
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: $grid-gutter-width $grid-gutter-width 0;
  }
  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width;
  }
}

.filters__column {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-only(md) {
    @include make-col(4);
  }
}

.filters__desktop {
  padding-bottom: $spacer*0.5;
  padding-top: $spacer*0.5;
  &_underline {
    border-bottom: $ue-dashed-border;
  }
}

.filters__mobile {
  padding-bottom: $grid-gutter-width;
}

.filters__link {
  color: $ue-black;
  font-size: $ue-font-size-9;
  font-family: $ue-subheader-font;
  &:focus,
  &:hover {
    color: $ue-black;
    text-decoration: none;
  }
  &_active {
    font-weight: 700;
  }
  @each $category, $color in $category-colors {
    &_#{$category} {
      &:focus,
      &:hover {
        color: $color;
        text-decoration: none;
      }
    }
    &_#{$category}.filters__link_active {
      color: $color;
    }
  }
}
