@use 'sass:map';
@import '../../../scss/utilities';

.wrapper {
  &_bordered {
    margin-bottom: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    border-bottom: $ue-dashed-border;
  }
}
