@import '../../../scss/utilities';

.date {
  padding-bottom: $spacer*0.5;
  display: flex;
  align-items: center;
  font-family: $ue-subheader-font;
  text-transform: uppercase;
}

.date__display {
  color: $ue-light-gray;
  font-size: $ue-font-size-10;
  font-weight: 700;
}

.date__label {
  padding-right: $grid-gutter-width*0.5;
  color: $ue-medium-gray;
  font-size: $ue-font-size-7;
  font-weight: 400;
}
