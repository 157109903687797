@import '../../../scss/utilities';

$xs-height: 292px;
$sm-height: 389px;
$md-height: 350px;
$lg-height: 312px;
$xl-height: 403px;
// Appproximate height of date container
// plus headline container with a 1 line
// headline. This allows the headline to
// grow without covering more of the image.
$offset: 120px;

.latest {
  @include media-breakpoint-only(md) {
    @include make-container();
  }
  @include media-breakpoint-up(lg) {
    padding-left: $grid-gutter-width*0.5;
  }
}

.latest__container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  padding-top: $xs-height - $offset;
  @include media-breakpoint-only(sm) {
    padding-top: $sm-height - $offset;
  }
  @include media-breakpoint-only(md) {
    padding-top: $md-height - $offset;
  }
  @include media-breakpoint-only(lg) {
    padding-top: $lg-height - $offset;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $xl-height - $offset;
  }
  @each $category, $color in $category-colors {
    &_#{$category}Even {
      background-image: url('/images/categories/#{$category}/latest-#{$category}-even-495.png');
      // stylelint-disable plugin/no-unsupported-browser-features
      @media only screen and (min-resolution: 200dpi),
        only screen and (min-resolution: 1.25dppx) {
        background-image: url('/images/categories/#{$category}/latest-#{$category}-even-990-retina.png');
      }
      // stylelint-enable plugin/no-unsupported-browser-features
      @include media-breakpoint-up(sm) {
        background-image: url('/images/categories/#{$category}/latest-#{$category}-even-780.png');
      }
    }
    &_#{$category}Odd {
      background-image: url('/images/categories/#{$category}/latest-#{$category}-odd-495.png');
      // stylelint-disable plugin/no-unsupported-browser-features
      @media only screen and (min-resolution: 200dpi),
        only screen and (min-resolution: 1.25dppx) {
        background-image: url('/images/categories/#{$category}/latest-#{$category}-odd-990-retina.png');
      }
      // stylelint-enable plugin/no-unsupported-browser-features
      @include media-breakpoint-up(sm) {
        background-image: url('/images/categories/#{$category}/latest-#{$category}-odd-780.png');
      }
    }
  }
}

.latest__content {
  margin: 0 auto;
  background-color: $ue-white;
  @include media-breakpoint-up(md) {
    flex: 0 0 100%;
    margin: 0;
    background-color: transparent;
  }
}

.latest__date {
  padding-top: $grid-gutter-width*0.5;
  background-color: $ue-white;
  @include media-breakpoint-down(sm) {
    @include make-container();
    @include make-container-max-widths();
  }
  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

.latest__headline {
  padding-right: $grid-gutter-width*0.5;
  @include media-breakpoint-up(md) {
    width: 80%;
  }
}

.latest__teaser {
  background-color: $ue-white;
  @include media-breakpoint-down(sm) {
    @include make-container();
    @include make-container-max-widths();
  }
}
