@import '../../../scss/utilities';

.newsletter {
  @include make-row();
  &_sidebar {
    width: 300px;
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
  }
  &_feature {
    padding-top: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;
  }
}

.newsletter__container {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  align-items: center;
  justify-content: center;
  &_logo {
    margin-bottom: $grid-gutter-width;
  }
  &_split {
    @include media-breakpoint-up(md) {
      @include make-col(6);
      margin-bottom: 0;
    }
  }
  &_split.newsletter__container_logo {
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      justify-content: flex-end;
      padding: 70px $grid-gutter-width 0 0;
    }
  }
  &_split.newsletter__container_form {
    @include media-breakpoint-up(md) {
      justify-content: flex-start;
      padding-left: $grid-gutter-width;
    }
  }
}

.newsletter__logo {
  width: 23.8rem;
  height: 8.8rem;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/images/logos/dear-abby.svg');
}

.newsletter__text {
  font-family: $ue-subheader-font;
  &_cta {
    font-weight: 700;
  }
  &_label {
    font-weight: 400;
  }
}

.form {
  width: $ue-sidebar-width;
  // Nesting here for one-off style reqs
  label {
    width: 100%;
  }
  button {
    width: 100%;
  }
}

.form__group {
  @include ue-form-group;
  margin-bottom: $grid-gutter-width*0.5;
}

.form__input {
  @include ue-form-input;
}

.form__icon {
  @include ue-form-icon;
}

.form__valid {
  @include ue-form-valid;
}

.form__invalid {
  @include ue-form-invalid;
}

.form__success {
  @include ue-form-success;
}

.form__error {
  @include ue-form-error;
}
