@import '../../../scss/utilities';

.divider {
  @include make-row();
  margin-bottom: $grid-gutter-width;
  &_padded {
    margin-top: $grid-gutter-width;
  }
}

.divider__container {
  @include make-col-ready();
  @include make-col(12);
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      // stylelint-disable plugin/no-unsupported-browser-features
      width: calc(100% - #{$grid-gutter-width});
      // stylelint-enable plugin/no-unsupported-browser-features
      border-top: $ue-dashed-border;
      position: absolute;
      left: 15px;
      right: 0;
      z-index: -1;
    }
  }
}

.divider__wrapper {
  @include media-breakpoint-up(md) {
    padding: 0 $grid-gutter-width;
    background-color: $ue-white;
  }
}
