@mixin ue-form-group {
  position: relative; // support .form__icon
  color: $ue-dark-gray;
}

@mixin ue-form-icon {
  position: absolute;
  right: $grid-gutter-width * 0.5;
  // stylelint-disable plugin/no-unsupported-browser-features
  top: 42px; // fallback
  // calculate icon height with: 2 lines of text, input margin + border + padding, minus half icon size
  top: calc(
    #{$font-size-base * 2} + #{$spacer} + #{$input-border-width * 2} + #{$input-padding-y} -
      8px
  );
  // stylelint-enable plugin/no-unsupported-browser-features
}

@mixin ue-form-valid {
  .form__icon {
    color: $success;
  }
  .form__input {
    border-color: $success;
  }
}

@mixin ue-form-invalid {
  .form__icon {
    color: $danger;
  }
  .form__input {
    border-color: $danger;
  }
}

@mixin ue-form-error {
  margin-top: $spacer;
  margin-bottom: 0;
  color: $danger;
}

@mixin ue-form-success {
  margin-top: $spacer;
  margin-bottom: 0;
  color: $success;
}

@mixin ue-form-info {
  margin-top: $spacer;
  margin-bottom: 0;
  color: $ue-medium-gray;
}

@mixin ue-form-input {
  display: block;
  width: 100%;
  height: $input-height;
  margin-top: $spacer * 0.5;
  padding-top: $input-padding-y;
  padding-bottom: $input-padding-y;
  padding-left: $input-padding-x;
  padding-right: 4.5rem; // allow space for icon
  font-family: $input-font-family;
  font-size: $ue-font-size-8;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: $input-color;
  background-color: $input-bg;
  background-clip: padding-box;
  border: $input-border-width solid $input-border-color;

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  border-radius: $input-border-radius;

  box-shadow: $input-box-shadow;
  transition: $input-transition;

  // Unstyle the caret on `<select>`s in IE10+.
  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  // Remove select outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $input-color;
  }

  // Customize the `:focus` state to imitate native WebKit styles.
  // @include form-control-focus($ignore-warning: true);
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    box-shadow: $input-focus-box-shadow;
  }

  // Placeholder
  &::placeholder {
    color: $input-placeholder-color;
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &:disabled,
  &[readonly] {
    background-color: $input-disabled-bg;
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

@mixin ue-form-check {
  position: relative;
  display: block;
}

@mixin ue-form-check-input {
  position: absolute;
  top: 0;
  left: $grid-gutter-width * 0.5;
  margin-top: $spacer * 0.5;
}

@mixin ue-form-check-label {
  padding-left: 2.5rem;
}
