@import '../../../../scss/utilities';

.mobile {
  @include make-container();
  @include make-container-max-widths();
  @include media-breakpoint-up(lg) {
    display: none; // react-responsive fallback
  }
}

.mobile__filterNavigation {
  @include make-row();
}

.mobile__control {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width;
}

.mobile__controlText {
  padding-right: 1rem;
  font-family: $ue-subheader-font;
  font-size: $ue-font-size-9;
}

.mobile__activeFilter {
  font-weight: 700;
  text-transform: capitalize;
}

.mobile__filters {
  @include make-col-ready();
  @include make-col(12);
}

.mobile__filterContainer {
  background-color: $ue-lighter-gray;
  border-radius: 10px;
  box-shadow: 0px 0px 30px $ue-shadow;
  &_closed {
    max-height: 0;
    overflow: hidden;
    transition: all 350ms ease-in-out;
  }
  &_open {
    margin-bottom: $grid-gutter-width;
    max-height: 2000px;
    transition: max-height 350ms ease-in-out;
  }
}
