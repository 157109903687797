@import '../../../scss/utilities';

.teaser {
  margin-bottom: 0;
  font-family: $ue-body-font;
}

.teaser__more {
  color: $ue-black;
  font-family: $ue-subheader-font;
  font-weight: 700;
  font-size: $ue-font-size-9;
  white-space: nowrap;
  &:focus,
  &:hover {
    color: $ue-black;
  }
  @each $category, $color in $category-colors {
    &_#{$category} {
      color: $color;
      &:focus,
      &:hover {
        color: $color;
      }
    }
  }
}

.teaser__moreDecorator {
  padding-left: $spacer*0.5;
  font-size: 1.2rem;
}
