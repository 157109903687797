@import '../../scss/utilities';

.recommendations {
  @include make-row();
}

.recommendations__header {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width * 2;
  font-family: $ue-header-font;
  font-weight: 700;
  text-align: center;
  font-size: $ue-font-size-7;
  @include media-breakpoint-up(md) {
    text-align: left;
    font-size: $ue-font-size-5;
  }
}

.recommendations__card {
  @include make-col-ready();
  @include make-col(12);
  margin-bottom: $grid-gutter-width * 2;
  @include media-breakpoint-only(md) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  &_drop {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}
